import React from 'react';
import { NButton } from 'nayan';
import { Link } from 'react-router-dom';
import Bubbles from './Bubbles';

const Banner = () => {
  return (
    <section className="bg-card flex items-center justify-center overflow-hidden" id="home">
      <div className="container mx-auto">
        <div className="grid md:grid-cols-2 grid-cols-1 gap-8">
          <div className="flex flex-col justify-center md:p-0 py-5 px-3">
            <div className="text-3xl mb-5 text">Enterprise Intranet Solution</div>
            <div className="text-base leading-loose mb-5 text">
              Worklage is not just a collaboration tool, it can also be Intranet for your business where everyone can share their ideas and
              achievements and celebrate them together. Worklage also automates your daily tasks in one place like, Managing Employees, Leaves,
              Documents, Forms, Assets, Pages, Policies, Projects, Sales, Purchases, Invoices, Accounting and lot more.
            </div>
            <div className="p-0">
              {/*<Link to={CONFIG.ABOUT_URL} target="_blank" className="mr-3">*/}
              {/*  <NButton className="px-4 bg-green-700 hover:bg-green-800 border-green-700">ABOUT WORKLAGE</NButton>*/}
              {/*</Link>*/}
              <Link to="/signup">
                <NButton className="px-4">START FREE</NButton>
              </Link>
            </div>
          </div>
          <Bubbles>
            <>
              <div className="p-5 bg-white rounded-lg flex items-center justify-between space-x-8">
                <div className="flex-1">
                  <div className="h-4 md:w-48 w-36 bg-gray-300 rounded" />
                </div>
                <div>
                  <div className="md:w-24 w-16 h-6 rounded-lg bg-purple-300" />
                </div>
              </div>
              <div className="p-5 bg-white rounded-lg flex items-center justify-between space-x-8">
                <div className="flex-1">
                  <div className="h-4 md:w-56 w-30 bg-gray-300 rounded" />
                </div>
                <div>
                  <div className="md:w-16 w-12 h-6 rounded-lg bg-yellow-300" />
                </div>
              </div>
              <div className="p-5 bg-white rounded-lg flex items-center justify-between space-x-8">
                <div className="flex-1">
                  <div className="h-4 w-44 bg-gray-300 rounded" />
                </div>
                <div>
                  <div className="md:w-28 w-20 h-6 rounded-lg bg-pink-300" />
                </div>
              </div>
              <div className="p-5 bg-white rounded-lg flex items-center justify-between space-x-8">
                <div className="flex-1">
                  <div className="h-4 w-44 bg-gray-300 rounded" />
                </div>
                <div>
                  <div className="md:w-24 w-12 h-6 rounded-lg bg-green-300" />
                </div>
              </div>
            </>
          </Bubbles>
        </div>
      </div>
    </section>
  );
};

export default Banner;
