import React from 'react';
import { isExternal } from '@worklage/shared';
import { ChevronDown } from 'lucide-react';
import { NMenu, NMenuItem } from 'nayan';
import { useNavigate } from 'react-router-dom';

const GroupsMenu = () => {
  const navigate = useNavigate();

  return (
    <span className="cursor-pointer" onClick={e => e.stopPropagation()}>
      <NMenu trigger={<ChevronDown className="text-text w-5 h-5 mr-2" />}>
        {!isExternal() && <NMenuItem title="Groups" onClick={() => navigate('/groups')} />}
        <NMenuItem title="My Groups" onClick={() => navigate('/groups/mine')} />
      </NMenu>
    </span>
  );
};

export default GroupsMenu;
