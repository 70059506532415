import React, { useCallback } from 'react';
import { AUTH_TABS } from '@worklage/shared';
import { Navigate } from 'react-router-dom';
import { isLogin } from '../../services/AuthService';
import DownloadItem from '../helpers/download/DownloadItem';
import Footer from '../helpers/footer/Footer';
import Wrapper from '../helpers/wrapper/Wrapper';
import Forgot from './Forgot';
import Login from './Login';
import Signup from './Signup';

const Auth = () => {
  const getDefaultTab = useCallback(() => {
    if (window.location.href.includes('/signup')) {
      return AUTH_TABS.SIGNUP;
    } else if (window.location.href.includes('/forgot-password')) {
      return AUTH_TABS.FORGOT;
    } else {
      return AUTH_TABS.LOGIN;
    }
  }, [window.location]);

  if (isLogin()) {
    return <Navigate to="/timeline" />;
  }

  return (
    <Wrapper>
      <div className="flex justify-center">
        <div className="w-full md:max-w-[450px]">
          <DownloadItem />
          {getDefaultTab() === AUTH_TABS.LOGIN && <Login />}
          {getDefaultTab() === AUTH_TABS.SIGNUP && <Signup />}
          {getDefaultTab() === AUTH_TABS.FORGOT && <Forgot />}
          <Footer />
        </div>
      </div>
    </Wrapper>
  );
};

export default Auth;
