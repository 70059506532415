import React from 'react';

interface Props {
  name: string;
  lightColor: string;
  darkColor: string;
}

const Tag = (props: Props) => {
  return (
    <span className="px-2 py-0.5 mr-1 mb-1 rounded-lg text-[10px] uppercase" style={{ backgroundColor: props.lightColor, color: props.darkColor }}>
      {props.name}
    </span>
  );
};

export default Tag;
