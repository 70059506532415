import React, { useEffect, useState } from 'react';
import {
  formatQueryData,
  getPreferences,
  hasSpecificAccess,
  isExternal,
  useAppStore,
  useBusinessData,
  USER_ROLES,
  useUserStore
} from '@worklage/shared';
import {
  AlignJustify,
  BadgePercent,
  Bell,
  Building2,
  CalendarClock,
  FileCog,
  FileText,
  FolderClosed,
  Home,
  LayoutGrid,
  MessageSquare,
  QrCode,
  RemoveFormatting,
  ShoppingCart,
  User2,
  Users2
} from 'lucide-react';
import { NSheet } from 'nayan';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import { isLogin } from '../../../services/AuthService';
import HeaderStatic from '../../about/Header';
import Dot from '../Dot';
import SearchBox from '../search-box/SearchBox';

interface Props {
  isLoaded: boolean;
  messages: number;
  notifications: number;
}

const HeaderMenu = (props: Props) => {
  const navigate = useNavigate();
  const { isLoaded, notifications, messages } = props;
  const businessUuid = useUserStore.getState().UBUUID;
  const { data } = useBusinessData(businessUuid);
  const business = formatQueryData(data);

  return (
    <div className="w-full flex flex-col md:flex-row justify-between items-center">
      <div className="hidden md:block">
        <SearchBox />
      </div>
      <ul className="nav-menu w-full md:w-auto flex flex-col md:flex-row items-center">
        <li className="p-4 md:p-0 md:ml-4 w-full md:w-auto">
          <NavLink className="nav-link flex md:flex-col items-center justify-start md:justify-center" to="/timeline">
            <Home className="fill-muted m-0 md:m-auto" size="1.6em" />
            <div className="text-base md:text-xs mt-0.5 ml-2 md:ml-0">Home</div>
          </NavLink>
        </li>
        <li className="p-4 md:p-0 md:ml-4 w-full md:w-auto">
          <NavLink className="nav-link flex md:flex-col items-center justify-start md:justify-center" to="/community">
            <Users2 className="fill-muted m-0 md:m-auto" size="1.6em" />
            <div className="text-base md:text-xs mt-0.5 ml-2 md:ml-0">Community</div>
          </NavLink>
        </li>
        <li className="p-4 md:p-0 md:ml-4 w-full md:w-auto">
          <NavLink className="nav-link flex md:flex-col items-center justify-start md:justify-center relative" to="/messages">
            <MessageSquare className="fill-muted m-0 md:m-auto" size="1.6em" />
            <div className="text-base md:text-xs mt-0.5 ml-2 md:ml-0">Messages</div>
            {isLoaded && !!messages && <Dot className="left-[15px] md:left-[30px] -top-[2px]" />}
          </NavLink>
        </li>
        <li className="p-4 md:p-0 md:ml-4 w-full md:w-auto">
          <NavLink className="nav-link flex md:flex-col items-center justify-start md:justify-center relative" to="/notifications">
            <Bell className="fill-muted m-0 md:m-auto" size="1.6em" />
            <div className="text-base md:text-xs mt-0.5 ml-2 md:ml-0">Notifications</div>
            {isLoaded && !!notifications && <Dot className="left-[11px] md:left-[33px] -top-[2px]" />}
          </NavLink>
        </li>
        <li className="p-4 md:p-0 md:ml-4 w-full md:w-auto">
          <NavLink className="nav-link flex md:flex-col items-center justify-start md:justify-center" to="/profile">
            <User2 className="fill-muted m-0 md:m-auto" size="1.6em" />
            <div className="text-base md:text-xs mt-0.5 ml-2 md:ml-0">Account</div>
          </NavLink>
        </li>
      </ul>
      <div className="md:hidden w-full">
        {!isExternal() && (
          <div className="text-base text-muted p-4 flex items-center" onClick={() => navigate('/groups')}>
            <Users2 size={20} className="text-text fill-muted mr-2" />
            <span className="mt-0.5">Groups</span>
          </div>
        )}
        <div className="text-base text-muted p-4 flex items-center" onClick={() => navigate('/businesses')}>
          <Building2 size={20} className="text-text fill-muted mr-2" />
          <span className="mt-0.5">Businesses</span>
        </div>
        <div className="text-base text-muted p-4 flex items-center" onClick={() => navigate('/integrations')}>
          <FileCog size={20} className="text-text fill-muted mr-2" />
          <span className="mt-0.5">Integrations</span>
        </div>
        {!!business?.enableApps && (
          <>
            <div className="text-muted text-base p-4 box-margin">Applications</div>
            {!!business?.appsConfig?.projects && (
              <div className="text-base text-muted p-4 flex items-center" onClick={() => navigate('/projects?type=active')}>
                <LayoutGrid size={20} className="text-text fill-muted mr-2" />
                <span className="mt-0.5">Manage Projects</span>
              </div>
            )}
            {!!business?.appsConfig?.assets && (
              <div className="text-base text-muted p-4 flex items-center" onClick={() => navigate('/assets')}>
                <QrCode size={20} className="text-text fill-muted mr-2" />
                <span className="mt-0.5">Manage Assets</span>
              </div>
            )}
            {!!business?.appsConfig?.forms && (
              <div className="text-base text-muted p-4 flex items-center" onClick={() => navigate('/forms')}>
                <RemoveFormatting size={20} className="text-text fill-muted mr-2" />
                <span className="mt-0.5">Manage Forms</span>
              </div>
            )}
            {!!business?.appsConfig?.pages && (
              <div className="text-base text-muted p-4 flex items-center" onClick={() => navigate('/pages')}>
                <FileText size={20} className="text-text fill-muted mr-2" />
                <span className="mt-0.5">Manage Pages</span>
              </div>
            )}
            {!!business?.appsConfig?.documents && (
              <div className="text-base text-muted p-4 flex items-center" onClick={() => navigate('/documents')}>
                <FolderClosed size={20} className="text-text fill-muted mr-2" />
                <span className="mt-0.5">Manage Documents</span>
              </div>
            )}
            {!!business?.appsConfig?.leaves && (
              <div className="text-base text-muted p-4 flex items-center" onClick={() => navigate('/leaves')}>
                <CalendarClock size={20} className="text-text fill-muted mr-2" />
                <span className="mt-0.5">Manage Leaves</span>
              </div>
            )}
            {(hasSpecificAccess(USER_ROLES.SALES) || hasSpecificAccess(USER_ROLES.SALES_LIMITED)) && !!business?.appsConfig?.sales && (
              <div className="text-base text-muted p-4 flex items-center" onClick={() => navigate('/sales/quotations')}>
                <BadgePercent size={20} className="text-text fill-muted mr-2" />
                <span className="mt-0.5">Manage Sales</span>
              </div>
            )}
            {(hasSpecificAccess(USER_ROLES.PURCHASES) || hasSpecificAccess(USER_ROLES.PURCHASES_LIMITED)) && !!business?.appsConfig?.purchases && (
              <div className="text-base text-muted p-4 flex items-center" onClick={() => navigate('/purchases/orders')}>
                <ShoppingCart size={20} className="text-text fill-muted mr-2" />
                <span className="mt-0.5">Manage Purchases</span>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

const Header = () => {
  const location = useLocation();
  const messages = useAppStore((state: any) => state.messages);
  const notifications = useAppStore((state: any) => state.notifications);
  const isLoaded = useAppStore((state: any) => state.isLoaded);
  const [menu, setMenu] = useState(false);

  useEffect(() => {
    if (isLogin() && !isLoaded) {
      getPreferences();
    }
  }, []);

  useEffect(() => {
    setMenu(false);
    isLogin() && window.scrollTo(0, 0);
  }, [location.pathname]);

  if (!isLogin()) {
    return <HeaderStatic />;
  }

  return (
    <header className="bg-card fixed top-0 left-0 right-0 z-10 border-0 border-b border-border shadow">
      <div className="container mx-auto">
        <NSheet isOpen={menu} title="Worklage" onCloseSheet={() => setMenu(false)}>
          <HeaderMenu isLoaded={isLoaded} messages={messages} notifications={notifications} />
        </NSheet>
        <nav className="flex flex-row py-[5px] justify-between items-center">
          <Link to="/timeline">
            <img src="/logo.png" className="d-inline-block align-top main-logo" alt="Worklage Logo" loading="lazy" width={40} height={40} />
          </Link>
          <div className="block md:hidden">
            <SearchBox />
          </div>
          <div className="block md:hidden p-2 relative" onClick={() => setMenu(true)}>
            <AlignJustify className="w-6 h-6 text-text" />
            {isLoaded && (!!notifications || !!messages) && <Dot className="left-[23px] top-[8px]" />}
          </div>
          <div className="w-full hidden md:block">
            <HeaderMenu isLoaded={isLoaded} messages={messages} notifications={notifications} />
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;
