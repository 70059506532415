import React from 'react';
import { RESOURCES } from '@worklage/shared';

const Downloads = () => {
  return (
    <section className="bg-card flex flex-col justify-center text" id="downloads">
      <div className="container mx-auto">
        <div className="text-3xl text text-center mt-12 mb-5">Downloads</div>
        <div className="text text-base mb-8 text-center max-w-[900px] mx-auto">
          Connect and collaborate with anyone from anywhere with Worklage Applications.
        </div>
        <div className="mb-16 text-center">
          <a href="http://bit.ly/wl-play" target="_blank">
            <img className="image-container" src={RESOURCES.PLAY_STORE} alt="Worklage playstore" width={170} height={52} />
          </a>
        </div>
      </div>
    </section>
  );
};

export default Downloads;
