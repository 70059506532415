import React from 'react';
import { Navigate } from 'react-router-dom';
import { isLogin } from '../../services/AuthService';
import Applications from '../about/Applications';
import Banner from '../about/Banner';
import Credits from '../about/Credits';
import Downloads from '../about/Downloads';
import Features from '../about/Features';
import Footer from '../about/Footer';
import Header from '../about/Header';
import Pricing from '../about/Pricing';
import Seo from '../helpers/Seo';

const Home = () => {
  if (isLogin()) {
    return <Navigate to="/timeline" />;
  }

  return (
    <>
      <Seo />
      <Header />
      <Banner />
      <Credits />
      <Features />
      <Applications />
      <Downloads />
      <Pricing />
      <Footer />
    </>
  );
};

export default Home;
