import React from 'react';
import Footer from '../../about/Footer';
import Header from '../header/Header';

interface Props {
  children: any;
  isContainer?: boolean;
}

const Wrapper = (props: Props) => {
  const { isContainer = true } = props;

  return (
    <>
      <Header />
      {isContainer && <div className="main container mx-auto">{props.children}</div>}
      {!isContainer && <div className="main-static">{props.children}</div>}
      {!isContainer && <Footer />}
    </>
  );
};

export default Wrapper;
