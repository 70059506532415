import { CONFIG } from '@worklage/shared';
import { Helmet } from 'react-helmet';

const Seo = (props: { title?: string }) => {
  const { title = CONFIG.TITLE } = props;

  return (
    <Helmet>
      <title>{title}</title>
    </Helmet>
  );
};

export default Seo;
