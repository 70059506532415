import React from 'react';
import { clearLineBreaks, useFollowUserMutation } from '@worklage/shared';
import { ButtonSize, NButton, useToast } from 'nayan';
import { useNavigate } from 'react-router-dom';
import { Storage } from '../../services/StorageService';
import { getUserImage } from '../../services/WebUtils';

interface Props {
  user: any;
  isModal?: boolean;
  noMargin?: boolean;
}

const CommunityUsersItem = (props: Props) => {
  const navigate = useNavigate();
  const toast = useToast();
  const myUser = Storage.get('UUID');
  const { user, noMargin = true, isModal = false } = props;
  const follwUserMutation = useFollowUserMutation();

  const followUser = e => {
    e.stopPropagation();
    const type = e.target.innerText;
    follwUserMutation.mutateAsync({ type, user }).then(() => {
      toast(`You have successfully ${type === 'Following' ? 'un followed' : 'followed'} ${user.name}.`);
    });
  };

  return (
    <div className="cursor-pointer">
      <div
        className={`${noMargin ? '' : 'box-margin'} p-2 sugg-item no-radius`}
        onClick={() => (isModal ? navigate('/profile/' + user.uuid, { replace: true }) : navigate('/profile/' + user.uuid, { replace: true }))}>
        <div className="sugg-user-block">
          <div className="sugg-user cursor-pointer">
            <img src={getUserImage(user.image)} className="image-container sugg-user-image border" alt="user" loading="lazy" />
            <div>
              <div className="m-0 sugg-user-text limit-one-line text">{user.name}</div>
              <div className="m-0 sugg-user-sub-text text text-xs pt-1 limit-one-line">{clearLineBreaks(user.about) || user.id}</div>
            </div>
          </div>
          {user.uuid !== myUser && (
            <div>
              <NButton size={ButtonSize.SM} isOutline onClick={followUser}>
                {!!user.isFollowing ? 'Following' : 'Follow'}
              </NButton>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default React.memo(CommunityUsersItem);
