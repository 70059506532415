import React, { useState } from 'react';
import { resetPassword } from '@worklage/shared';
import { Field, Form, Formik } from 'formik';
import { NButton, useToast } from 'nayan';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import Required from '../helpers/elements/Required';
import Footer from '../helpers/footer/Footer';
import Seo from '../helpers/Seo';
import Wrapper from '../helpers/wrapper/Wrapper';

const Reset = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const initialValues = { npass: '', rpass: '' };
  const { userUuid, resetUuid }: any = useParams();
  const toast = useToast();

  return (
    <Wrapper>
      <Seo title="Reset Password | Worklage" />
      <div className="flex justify-center">
        <div className="w-full md:max-w-[450px]">
          <Formik
            initialValues={initialValues}
            onSubmit={(values, actions) => {
              setIsLoading(true);
              if (values.npass === values.rpass && values.npass.length > 3) {
                const data = { userUuid, resetUuid, new_password: values.npass };
                resetPassword(data)
                  .then((res: any) => {
                    toast(res.data.message);
                    navigate('/login', { replace: true });
                  })
                  .catch((err: any) => toast(err.message))
                  .finally(() => setIsLoading(false));
              } else {
                setIsLoading(false);
                toast('Password not valid, please enter correct password.');
              }
            }}>
            <Form>
              <div className="mb-3">
                <label className="text" htmlFor="password">
                  New password
                  <Required />
                </label>
                <Field className="form-control bg-card" type="password" name="npass" />
              </div>
              <div className="mb-3">
                <label className="text" htmlFor="password">
                  Re-type password
                  <Required />
                </label>
                <Field className="form-control bg-card" type="password" name="rpass" />
              </div>

              <div className="d-grid gap-3 mb-3">
                <NButton type="submit" className="w-full" isLoading={isLoading}>
                  RESET PASSWORD
                </NButton>
              </div>
            </Form>
          </Formik>

          <div className="text-center text">
            Already have account?{' '}
            <span className="text-primary cursor-pointer" onClick={() => navigate('/login', { replace: true })}>
              Login
            </span>
          </div>
          <Footer />
        </div>
      </div>
    </Wrapper>
  );
};

export default Reset;
