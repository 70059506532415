import React, { useEffect, useState } from 'react';
import { RestService, useUserStore } from '@worklage/shared';
import { NLoading } from 'nayan';
import App from './App';
import './index.css';
import { setUserFromStorage } from './services/WebUtils';

const RootApp = () => {
  const [load, setload] = useState(true);

  useEffect(() => {
    const userSub = useUserStore.subscribe((data: any) => {
      RestService.setToken(data.TOKEN);
    });
    setUserFromStorage().finally(() => setload(false));
    return () => {
      userSub();
    };
  }, []);

  if (load) {
    return <NLoading />;
  }

  return <App />;
};

export default RootApp;
