import React from 'react';
import { CONFIG } from '@worklage/shared';

const AcceptTerms = () => {
  return (
    <span className="text-text leading-5">
      By clicking on this you have accepted{' '}
      <a className="text-primary no-underline" href={CONFIG.TERMS_OF_USE_URL} target="_blank">
        Terms of Use
      </a>
      &nbsp; and &nbsp;
      <a className="text-primary no-underline" href={CONFIG.PRIVACY_POLICY_URL} target="_blank">
        Privacy Policy
      </a>
    </span>
  );
};

export default AcceptTerms;
