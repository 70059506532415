import React from 'react';
import { CONFIG } from '@worklage/shared';
import {
  AppWindow,
  BarChart4,
  Bell,
  Building2,
  CalendarClock,
  CalendarDays,
  CalendarHeart,
  Files,
  FileText,
  Flower,
  FolderSearch,
  FolderUp,
  Globe,
  Heart,
  LayoutDashboard,
  LayoutGrid,
  LayoutPanelLeft,
  Lightbulb,
  Lock,
  Megaphone,
  MessageSquare,
  MessagesSquare,
  Pencil,
  PieChart,
  ShieldCheck,
  Smartphone,
  TerminalSquare,
  User2,
  UserCog2,
  UserPlus2,
  Users2,
  Video
} from 'lucide-react';
import { Link } from 'react-router-dom';
import FeaturesItem from './FeaturesItem';

const features = [
  {
    title: 'Timeline',
    text: "Get a personalized view of what's happening in your organization. Share your thoughts, receive updates from co-workers, and make your workplace a more interactive one.",
    icon: AppWindow
  },
  {
    title: 'Announcements',
    text: "Bring employees on the same page on important updates. Whether it's within your team or for the entire company, pin announcements on top of the feed so everyone keeps track of what's important.",
    icon: Megaphone
  },
  {
    title: 'Polls',
    text: "Find out what's on employees' minds by running a poll and getting real time results on what they think is the best idea or solution.",
    icon: BarChart4
  },
  {
    title: 'Schedule Posts',
    text: 'Plan and make sure the right content reaches employees at the right time by scheduling them. This is especially useful to bring relevant information to employees in different time zones or those working in shifts.',
    icon: CalendarClock
  },
  {
    title: 'Like, Comment and share',
    text: 'Make your digital workplace truly social by allowing employees to interact with the content that is shared on your network.',
    icon: Heart
  },
  {
    title: 'Notifications',
    text: "Stay informed on everything that's going on through push notifications. Pick and choose when and for what you need to be notified so you don't get overwhelmed.",
    icon: Bell
  },
  {
    title: 'Ideas',
    text: 'Encourage the free flow of ideas by letting employees share them in this exclusive space meant for it. Upvote ideas, assign a status, and track an idea, from its brainstorming phase to its implementation.',
    icon: Lightbulb
  },
  { title: 'Profiles', text: 'Help employees get to know each other better with rich profiles that showcase who they are.', icon: User2 },
  {
    title: 'People Directory',
    text: 'Provide employees access to the member directory to make it easier for them to find the right people when needed.',
    icon: FolderSearch
  },
  {
    title: 'Follow and Get Followed',
    text: 'Make your workplace more interactive by letting employees share and receive updates from their co-workers',
    icon: UserPlus2
  },
  {
    title: 'Org chart',
    text: 'Build transparency by making sure your organization chart is accessible to all employees by making it available on your Connect network.',
    icon: Building2
  },
  {
    title: 'Peer-Peer Communication',
    text: 'Create an environment that enables employees to reach out to each other without barriers by enabling one-on-one chat, audio, and video calling.',
    icon: MessageSquare
  },
  {
    title: 'Group Communication',
    text: 'Unify all your employees in a common space by creating an groups on specific topics. Share announcements, create events, conduct Town Halls, and use this group for any activity that involves specific set of people.',
    icon: MessagesSquare
  },
  {
    title: 'External users',
    text: 'Add external users with limited access, so you can interact with all the external users right from worklage. and invite them to manage projects, assets and others.',
    icon: UserCog2
  },
  {
    title: 'Groups',
    text: 'Create collaborative spaces for teams to hold conversations, conduct meetings, share documents, assign and track tasks, and bring everything they need to a common space.',
    icon: Users2
  },
  {
    title: 'Video conferencing',
    text: "In today's world of remote and hybrid work, it is important to ensure everybody's included. Be it introducing new team members or work discussions, create a more collaborative environment through video conferencing.",
    icon: Video
  },
  {
    title: 'Tasks & Boards',
    text: 'Create work plans, break them down into actionable tasks, assign and track the progress of work, and convert all your ideas into results.',
    icon: LayoutGrid
  },
  {
    title: 'Files',
    text: "Maintain a document repository within Worklage so all the files your team needs are always within everyone's reach. Collaboratively create documents, spreadsheets or presentations together with your co-workers.",
    icon: Files
  },
  {
    title: 'Calendar',
    text: 'Carry an updated schedule with you anywhere you go by using the calendar to keep track of company events, team meetings, and also, any deadlines you may have.',
    icon: CalendarDays
  },
  {
    title: 'Events',
    text: "Whether it's a conference or a company tournament, create events, invite co-workers, track RSVPs , and trigger reminders.",
    icon: CalendarHeart
  },
  {
    title: 'Custom apps',
    text: 'When it comes to automating, there is no one-size-fits-all solution. Choose from a list of our pre-built apps and customize them, or build your own.',
    icon: LayoutPanelLeft
  },
  {
    title: 'Forms',
    text: 'Using our drag-and-drop builder, build simple form-based apps that can gather information from your team and do away with repetitive manual work.',
    icon: FileText
  },
  {
    title: 'Reports',
    text: 'Generate reports based on the data that is gathered by your app and interpret them through reporting tools like charts and spreadsheets.',
    icon: LayoutDashboard
  },
  { title: 'Custom Domain', text: "Allow employees to access Worklage from your company's domain.", icon: Globe },
  {
    title: 'Custom Styles',
    text: 'Speak your brand and bring a sense of familiarity by customizing the look and feel of your network.',
    icon: Flower
  },
  {
    title: 'Branded mobile apps',
    text: 'Get a fully customized mobile destination for all internal and employee communications.',
    icon: Smartphone
  },
  {
    title: 'Single-sign-on',
    text: 'Integrate with all major identity and access management services to provide employees with one-click access to their Worklage app.',
    icon: Lock
  },
  {
    title: 'Custom roles & permissions',
    text: 'Create additional user roles and assign permissions to define the scope of members in your network.',
    icon: ShieldCheck
  },
  {
    title: 'Moderation',
    text: "Monitor the content that members share to make sure that only content that's relevant and needed is shared on the network.",
    icon: Pencil
  },
  {
    title: 'Analytics',
    text: 'Get the numbers and insights on the most active groups, users, posts, comments, forums, and more to help you understand how members are interacting in your organization.',
    icon: PieChart
  },
  { title: 'Data Export', text: "Secure your data by backing up your team's conversations, forums, manuals, and files.", icon: FolderUp },
  {
    title: 'API',
    text: 'Use APIs to integrate and connect with any third-party apps. Get updates from other work applications without having to switch tabs.',
    icon: TerminalSquare
  }
];

const Features = () => {
  return (
    <section className="bg-card flex flex-col justify-center text" id="features">
      <div className="container mx-auto">
        <div className="text-3xl text text-center mt-12 mb-5">Features</div>
        <div className="text text-base mb-12 text-center max-w-[900px] mx-auto">
          Worklage helps teams stay connected. Use our familiar features to share information, engage employees, build culture and connect people
          everywhere. We’ll even help you measure the impact so you know what’s working. Checkout how Worklage can help your business grow.
        </div>
        <div className="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 grid-cols-1 gap-x-12 gap-y-16 mb-16">
          {features.map(f => (
            <FeaturesItem key={f.title} title={f.title} text={f.text} icon={f.icon} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Features;
