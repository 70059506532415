import React, { useState } from 'react';
import { forgotPassword } from '@worklage/shared';
import { Field, Form, Formik } from 'formik';
import { NButton, useToast } from 'nayan';
import { Link, useNavigate } from 'react-router-dom';
import Required from '../helpers/elements/Required';
import Seo from '../helpers/Seo';

const Forgot = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const initialValues = { email: '' };
  const [isLoading, setIsLoading] = useState(false);

  return (
    <>
      <Seo title="Forgot Password | Worklage" />
      <Formik
        initialValues={initialValues}
        onSubmit={(values, actions) => {
          setIsLoading(true);
          if (!!values.email) {
            forgotPassword({ email: values.email })
              .then((res: any) => {
                toast(res.data.message);
                navigate('/login', { replace: true });
              })
              .catch((err: any) => toast(err.message))
              .finally(() => setIsLoading(false));
          } else {
            setIsLoading(false);
            toast('Enter valid email address');
          }
        }}>
        <Form>
          <div className="mb-2">
            <label className="text" htmlFor="email">
              Email address
              <Required />
            </label>
            <Field className="form-control bg-card" type="email" name="email" />
          </div>

          <div className="d-grid gap-3 mb-2">
            <NButton type="submit" className="w-full" isLoading={isLoading}>
              RESET PASSWORD
            </NButton>
          </div>
        </Form>
      </Formik>

      <div className="text-center text">
        Don't have an account ?{' '}
        <Link className="text-primary cursor-pointer" to="/signup">
          Signup
        </Link>
      </div>
    </>
  );
};

export default Forgot;
