import React from 'react';
import { getSpecificColor } from '@worklage/shared';
import Tag from '../tag/Tag';

export default props => {
  const tags = props?.api ? props?.data?.tags.slice(0, 2) : props?.data?.tags;
  return (
    <span className="cursor-pointer" onClick={e => !props.enableClick && e.stopPropagation()}>
      {tags?.map((tag: any, index: number) => {
        const color = getSpecificColor(tag.color);
        return <Tag key={index} name={tag.name} lightColor={color?.light} darkColor={color?.dark} />;
      })}
    </span>
  );
};
