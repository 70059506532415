import React from 'react';
import { PlusCircle } from 'lucide-react';
import Seo from '../Seo';

interface Props {
  title: string;
  showButton?: boolean;
  buttonText?: string;
  buttonIcon?: any;
  clickButton?: () => void;
  children?: any;
  hasSeo?: boolean;
}

const SubHeader = (props: Props) => {
  const { hasSeo = false } = props;
  const Icon = props.buttonIcon || PlusCircle;

  return (
    <div className="box-margin flex justify-between items-center rounded max-w-full overflow-hidden">
      {hasSeo && <Seo title={`${props.title} | Worklage`} />}
      <div className="flex flex-row items-center w-full overflow-hidden">
        <div className="text-text text-xl mr-3 whitespace-nowrap">{props.title}</div>
        <div className="flex flex-row w-full">{props.children}</div>
      </div>
      {!!props.showButton && (
        <span className="cursor-pointer flex items-center" onClick={props.clickButton}>
          <Icon className="w-4 h-4 text-primary" />
          <span className="pl-1 text-primary">{props.buttonText || 'NEW'}</span>
        </span>
      )}
    </div>
  );
};

export default SubHeader;
