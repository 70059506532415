import React from 'react';
import { formatQueryData, useSuggestionsData } from '@worklage/shared';
import { NLoading } from 'nayan';
import { useNavigate } from 'react-router-dom';
import NoData from '../helpers/no-data/NoData';
import CommunityUsersItem from './CommunityUsersItem';

const CommunitySuggestions = () => {
  const navigate = useNavigate();
  const { isLoading, data } = useSuggestionsData();
  const suggestions = formatQueryData(data);

  return (
    <div>
      {isLoading && <NLoading />}
      {!isLoading && (
        <>
          {!suggestions.length && (
            <div className="box p-0">
              <NoData text="There are no user suggestions available" />
            </div>
          )}
          {!!suggestions.length && (
            <>
              <div className="flex flex-row justify-between items-center box-margin">
                <div className="text-muted text-base">Suggestions</div>
                <div className="cursor-pointer text-primary" onClick={() => navigate('/community')}>
                  More
                </div>
              </div>
              <div className="box p-0">
                {suggestions?.map((user: any) => (
                  <CommunityUsersItem noMargin={true} user={user} key={user.uuid} />
                ))}
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default CommunitySuggestions;
