import React, { useEffect, useState } from 'react';
import { AlignJustify, MoonStar, Sun } from 'lucide-react';
import { NButton, NSheet, THEMES, useLocalStorage } from 'nayan';
import { Link, useLocation } from 'react-router-dom';

const HeaderMenu = () => {
  const [theme, setTheme] = useLocalStorage('THEME', '');

  const toggleTheme = () => {
    const newTheme = theme === THEMES.DARK ? THEMES.LIGHT : THEMES.DARK;
    setTheme(newTheme);
    document.documentElement.setAttribute('data-theme', newTheme);
  };

  return (
    <div className="w-full flex flex-col md:flex-row justify-between items-center">
      <ul className="w-full md:w-auto flex flex-col md:flex-row items-center mx-auto">
        <li className="w-full md:w-auto">
          <Link className="text-base font-medium text-text px-3 py-3 block md:inline" to="/">
            Home
          </Link>
        </li>
        <li className="w-full md:w-auto">
          <Link className="text-base font-medium text-text px-3 py-3 block md:inline" to="/#features">
            Features
          </Link>
        </li>
        <li className="w-full md:w-auto">
          <Link className="text-base font-medium text-text px-3 py-3 block md:inline" to="/#applications">
            Applications
          </Link>
        </li>
        <li className="py-3 md:py-0 w-full md:w-auto">
          <Link className="text-base font-medium text-text px-3 py-3 block md:inline" to="/#downloads">
            Downloads
          </Link>
        </li>
        <li className="w-full md:w-auto">
          <Link className="text-base font-medium text-text px-3 py-3 block md:inline" to="/#pricing">
            Pricing
          </Link>
        </li>
      </ul>
      <div className="w-full md:w-auto form-inline md:text-center md:justify-center items-center mt-5 md:mt-0">
        <span className="p-2 mx-2 md:my-2 mr-2 text cursor-pointer" onClick={toggleTheme} title="Theme Switch">
          {theme !== THEMES.DARK && <MoonStar className="w-6 h-6 text-text" />}
          {theme === THEMES.DARK && <Sun className="w-6 h-6 text-text" />}
        </span>
        <Link to="/signup" className="mr-3">
          <NButton className="px-4 bg-green-700 hover:bg-green-800 border-green-700">Signup</NButton>
        </Link>
        <Link to="/login">
          <NButton className="px-4">Login</NButton>
        </Link>
      </div>
    </div>
  );
};

const Header = () => {
  const location = useLocation();
  const [menu, setMenu] = useState(false);

  useEffect(() => {
    const hash = location?.hash as string;
    let offsetPosition;
    const element = hash && document.getElementById(hash.slice(1, hash.length));
    if (!!hash && !!element) {
      const headerOffset = 50;
      const elementPosition = element.getBoundingClientRect().top as number;
      offsetPosition = elementPosition + window.scrollY - headerOffset;
    } else {
      offsetPosition = 0;
    }
    setMenu(false);
    window.scrollTo({ top: offsetPosition, behavior: 'smooth' });
  }, [location?.hash]);

  return (
    <header className="bg-card fixed top-0 left-0 right-0 z-10">
      <div className="container mx-auto">
        <NSheet isOpen={menu} title="Worklage" onCloseSheet={() => setMenu(false)}>
          <HeaderMenu />
        </NSheet>
        <nav className="flex flex-row py-[6px] justify-between items-center">
          <Link to="/">
            <img src="/logo.png" className="d-inline-block align-top main-logo" alt="Worklage Logo" loading="lazy" width={40} height={40} />
          </Link>
          <div className="block md:hidden p-2" onClick={() => setMenu(true)}>
            <AlignJustify className="w-6 h-6 text-text" />
          </div>
          <div className="w-full hidden md:block">
            <HeaderMenu />
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;
