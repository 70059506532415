import React from 'react';

interface Props {
  children: any;
}

const WrapperLeft: React.FC<Props> = (props: Props) => (
  <div className="sticky-left-block">
    <div>{props.children}</div>
  </div>
);

export default WrapperLeft;
