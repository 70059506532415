export class Storage {
  static set(key: string, value) {
    try {
      localStorage.setItem(key, JSON.stringify(value));
    } catch (e) {
      console.warn('Something wrong with local storage', JSON.stringify(e));
    }
  }

  static get(key: string) {
    const value = localStorage.getItem(key);
    return !!value && value !== 'undefined' ? JSON.parse(value) : undefined;
  }

  static unset(key: string) {
    localStorage.removeItem(key);
  }

  static unsetAll() {
    const theme = Storage.get('THEME');
    localStorage.clear();
    Storage.set('THEME', theme);
  }
}
