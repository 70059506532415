import React from 'react';
import Wrapper from './Wrapper';
import WrapperLeft from './WrapperLeft';
import WrapperRight from './WrapperRight';
import { getRightSidebarBlockType, getSidebarBlockType, SIDEBAR_BLOCK_TYPE } from './WrapperTwoColumn';

interface Props {
  type?: SIDEBAR_BLOCK_TYPE;
  children: any;
}

const WrapperThreeColumn = (props: Props) => {
  const { type = SIDEBAR_BLOCK_TYPE.HOME } = props;

  return (
    <Wrapper>
      <div className="grid grid-cols-9 gap-2.5">
        <div className="hidden md:block md:col-span-3 lg:col-span-2">
          <WrapperLeft>{getSidebarBlockType(type)}</WrapperLeft>
        </div>
        <div className="col-span-9 sm:col-span-9 md:col-span-6 lg:col-span-4">{props.children}</div>
        <div className="hidden md:block md:col-span-3 lg:col-span-3">
          <WrapperRight>{getRightSidebarBlockType(type)}</WrapperRight>
        </div>
      </div>
    </Wrapper>
  );
};

export default WrapperThreeColumn;
