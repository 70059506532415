import React from 'react';
import Applications from '../about/Applications';
import Banner from '../about/Banner';
import Credits from '../about/Credits';
import Downloads from '../about/Downloads';
import Features from '../about/Features';
import Footer from '../about/Footer';
import Header from '../about/Header';
import Pricing from '../about/Pricing';
import Seo from '../helpers/Seo';
import Wrapper from '../helpers/wrapper/Wrapper';

const About = () => {
  return (
    <Wrapper isContainer={false}>
      <Seo />
      <Banner />
      <Credits />
      <Features />
      <Applications />
      <Downloads />
      <Pricing />
    </Wrapper>
  );
};

export default About;
