import React from 'react';
import { CONFIG } from '@worklage/shared';
import { NLink } from 'nayan';

const DownloadItem = () => {
  return (
    <div className="cursor-pointer">
      <div className={`box box-margin p-2`}>
        <div className="sugg-user-block">
          <div className="sugg-user cursor-pointer">
            <img src="/logo.png" className="image-container sugg-user-image" alt="logo" loading="lazy" width={45} height={45} />
            <div className="no-margin sugg-user-text">Download mobile application for better & faster experience</div>
          </div>
          <a className="" href={CONFIG.PLAY_STORE_URL} target="_blank">
            <NLink className="py-2 pl-2 font-semibold">Download</NLink>
          </a>
        </div>
      </div>
    </div>
  );
};

export default React.memo(DownloadItem);
