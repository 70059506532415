import React from 'react';
import { CONFIG } from '@worklage/shared';
import {
  BadgePercent,
  CalendarClock,
  FolderClosed,
  LayoutGrid,
  QrCode,
  RemoveFormatting,
  ScrollText,
  ShieldCheck,
  ShoppingCart,
  Users2
} from 'lucide-react';
import FeaturesItem from './FeaturesItem';

const applications = [
  {
    title: 'Employee Management',
    text: 'Say goodbye to spreadsheets and rigid systems. Choose Worklage employee management tool that makes employee management simple to plan, manage & track. Worklage lets HR managers and business owners streamline their employee-related activities. which includes employee onboarding, timesheets, leaves, performance, benefits, and personal details.',
    icon: Users2,
    link: `${CONFIG.ABOUT_URL}`
  },
  {
    title: 'Projects Management',
    text: 'Manage simple to complex projects and everything in between with Worklage. Manage any project your way and Collaborate seamlessly across teams and departments to gain visibility into the progress of your work. Keep everyone aligned with a platform they will enjoy using to ensure a smoother execution.',
    icon: LayoutGrid,
    link: `${CONFIG.ABOUT_URL}/applications/projects-management`
  },
  {
    title: 'Assets Management',
    text: 'Manage your organization’s assets, starting from small components to industrial equipment with ease. With our Asset management tool you can take control of the life-cycle performance of your assets and gain insight into asset maintenance costs in the short and long term.',
    icon: QrCode,
    link: `${CONFIG.ABOUT_URL}/applications/assets-management`
  },
  {
    title: 'Documents Management',
    text: 'Keeping your paperless office documents only on your computer or local server poses the risks of hard drive failure, fire, flood or burglary. And what if you want to access one of those important files away from the office ? Meet Worklage. The ultimate online document management system for any organization,the most user-friendly, Amazingly easy to use, highly secure and affordable.',
    icon: FolderClosed,
    link: `${CONFIG.ABOUT_URL}/applications/documents-management`
  },
  {
    title: 'Sales Management',
    text: 'Worklage Sales management tool bring’s in quality leads, nurture them, and turn them into happy, paying customers. Get a complete view of all customer data—personal details, all communication you’ve had with them, and more—all in a single place.',
    icon: BadgePercent,
    link: `${CONFIG.ABOUT_URL}/applications/sales-management`
  },
  {
    title: 'Procurements Management',
    text: 'With our procurement management software, you get the power of automation married to your procurement processes. Our processes are built to be self-reliant, in order to eliminate manual intervention as much as possible. This includes all important aspects of the end-to-end procurement process.',
    icon: ShoppingCart,
    link: `${CONFIG.ABOUT_URL}/applications/procurements-management`
  },
  {
    title: 'Invoice Management',
    text: 'Create, send and manage invoices online with Worklage Invoice Management, Capture your client’s attention along with their due payment through Worklage Invoice’s personalized and professional invoice templates. Worklage helps you track your sales, purchases & estimates in real-time. With Worklage, you can easily manage your inventory, file GST returns, create & share professional invoices.',
    icon: ScrollText,
    link: `${CONFIG.ABOUT_URL}/applications/invoices-management`
  },
  {
    title: 'Policies Management',
    text: 'Worklage believes performance is a result of effective risk management, Worklage empowers customers to develop an effective integrated risk management solution using a combination of software and learning content. Our cloud-based platform connects ESG, GRC, EHS, Sustainability and Ethics and Compliance Learning activities across the enterprise to streamline workflow and drive outcomes',
    icon: ShieldCheck,
    link: `${CONFIG.ABOUT_URL}/applications/policies-management`
  },
  {
    title: 'Leaves Management',
    text: 'Worklage leave management tool can help you establish your company’s leave policy/policies for its employees and also automate most of the process, making sure glitches do not occur. it can also increase employee satisfaction, reduce costs, improve team work, law complaint and accurate metrics.',
    icon: CalendarClock,
    link: `${CONFIG.ABOUT_URL}/applications/leaves-management`
  },
  {
    title: 'Forms Management',
    text: "Worklage forms management tool provides you with the capability to Designing forms is a breeze with Worklage's user-friendly interface. Similar to Google Forms, you can effortlessly add various question types such as multiple-choice, text-based, checkboxes, dropdowns, and more. Arrange and customize your questions to match your unique requirements, all within a sleek and modern design that's visually appealing to respondents.",
    icon: RemoveFormatting,
    link: `${CONFIG.ABOUT_URL}/applications/forms-management`
  }
];

const Applications = () => {
  return (
    <section className="bg-background flex flex-col justify-center text" id="applications">
      <div className="container mx-auto">
        <div className="text-3xl text text-center mt-12 mb-5">Applications</div>
        <div className="text text-base mb-12 text-center max-w-[900px] mx-auto">
          Worklage is not just limited to collaboration but also automates your daily tasks in one place like, Managing Employees, Leaves, Documents,
          Assets, Policies, Projects, Sales, Purchases, Invoices and lot more.
        </div>
        <div className="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 grid-cols-1 gap-x-12 gap-y-16 mb-16">
          {applications.map(a => (
            <FeaturesItem key={a.title} title={a.title} text={a.text} icon={a.icon} link={a.link} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Applications;
