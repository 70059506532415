import React from 'react';
import Footer from '../footer/Footer';

interface Props {
  children: any;
}

const WrapperRight: React.FC<Props> = (props: Props) => (
  <div className="sticky-right-block">
    <div>{props.children}</div>
    <Footer />
  </div>
);

export default WrapperRight;
