import React from 'react';

interface Props {
  className?: string;
}

const Dot = (props: Props) => {
  const { className = '' } = props;
  return <span className={`absolute w-[10px] h-[10px] rounded-full bg-primary border border-border ${className}`} />;
};

export default Dot;
