import React from 'react';
import { extendFormData, MESSAGE_TYPE, useAddMessageMutation } from '@worklage/shared';
import { Field, Form, Formik } from 'formik';
import * as _ from 'lodash';
import { NButton, useToast } from 'nayan';
import { v4 as uuidv4 } from 'uuid';

interface Props {
  user: any;
  isGroup?: boolean;
  closeModal: any;
}

const ChatsNew: React.FC<Props> = (props: Props) => {
  const { user, isGroup } = props;
  const toast = useToast();
  const initialValues = { message: '' };
  const addMessageMutation = useAddMessageMutation();

  return (
    <div className="chats-new p-3">
      <Formik
        initialValues={initialValues}
        onSubmit={(values, actions) => {
          if (values.message && values.message.length < 500) {
            const chat = _.assign(
              {},
              values,
              {
                frndUuid: user.uuid,
                uuid: uuidv4(),
                type: MESSAGE_TYPE.TEXT
              },
              isGroup ? { isGroup: true } : {}
            );
            const formData = new FormData();
            extendFormData(formData, chat);
            addMessageMutation.mutateAsync(formData).finally(() => {
              toast('Message sent successfully.');
              props.closeModal();
            });
          } else {
            toast('Message must not empty & less than 500 characters.');
          }
        }}>
        <Form>
          <div className="mb-3">
            <label className="text">
              Message to{' '}
              <strong>
                {user.name} {isGroup ? 'Group' : ''}
              </strong>
            </label>
          </div>

          <div className="mb-3">
            <Field as="textarea" className="form-control" name="message" placeholder="Say something!" rows="3" />
          </div>

          <div className="d-grid gap-2.5">
            <NButton type="submit">Send</NButton>
          </div>
        </Form>
      </Formik>
    </div>
  );
};

export default ChatsNew;
