import React from 'react';
import { formatQueryData, hasSpecificAccess, isExternal, useBusinessData, USER_ROLES, useUserStore } from '@worklage/shared';
import {
  BadgePercent,
  Building2,
  CalendarClock,
  FileCog,
  FileText,
  FolderClosed,
  LayoutGrid,
  QrCode,
  RemoveFormatting,
  ShoppingCart,
  User2,
  Users2
} from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import ProfileMini from '../profile/ProfileMini';

const HomeSidebar = () => {
  const navigate = useNavigate();
  const businessUuid = useUserStore.getState().UBUUID;
  const { data } = useBusinessData(businessUuid);
  const business = formatQueryData(data);

  const isActiveTab = (cond: string) => {
    return window.location.href.includes(cond);
  };

  return (
    <ProfileMini>
      <div className="box box-margin item-radius p-0 community-menu">
        {!isExternal() && (
          <div className={`sidebar-menu-item item-left-border${isActiveTab('/community') ? '-primary' : ''}`} onClick={() => navigate('/community')}>
            <User2 size={16} className="text-text" />
            <span>Manage Users</span>
          </div>
        )}
        {!isExternal() && (
          <div className={`sidebar-menu-item item-left-border${isActiveTab('/groups') ? '-primary' : ''}`} onClick={() => navigate('/groups')}>
            <Users2 size={16} className="text-text" />
            <span>Manage Groups</span>
          </div>
        )}
        <div className={`sidebar-menu-item item-left-border${isActiveTab('/business') ? '-primary' : ''}`} onClick={() => navigate('/businesses')}>
          <Building2 size={16} className="text-text" />
          <span>Manage Businesses</span>
        </div>
        <div
          className={`sidebar-menu-item item-left-border${isActiveTab('/integrations') ? '-primary' : ''}`}
          onClick={() => navigate('/integrations')}>
          <FileCog size={16} className="text-text" />
          <span>Manage Integrations</span>
        </div>
      </div>
      {!!business?.enableApps && (
        <>
          <div className="text-muted text-base box-margin">Applications</div>
          <div className="box box-margin item-radius p-0 community-menu mb-6">
            {!!business?.appsConfig?.projects && (
              <div
                className={`sidebar-menu-item item-left-border${isActiveTab('/projects') ? '-primary' : ''}`}
                onClick={() => navigate('/projects?type=active')}>
                <LayoutGrid size={16} className="text-text" />
                <span>Manage Projects</span>
              </div>
            )}
            {!!business?.appsConfig?.assets && (
              <div className={`sidebar-menu-item item-left-border${isActiveTab('/assets') ? '-primary' : ''}`} onClick={() => navigate('/assets')}>
                <QrCode size={16} className="text-text" />
                <span>Manage Assets</span>
              </div>
            )}
            {!!business?.appsConfig?.forms && (
              <div className={`sidebar-menu-item item-left-border${isActiveTab('/forms') ? '-primary' : ''}`} onClick={() => navigate('/forms')}>
                <RemoveFormatting size={16} className="text-text" />
                <span>Manage Forms</span>
              </div>
            )}
            {!!business?.appsConfig?.pages && (
              <div className={`sidebar-menu-item item-left-border${isActiveTab('/pages') ? '-primary' : ''}`} onClick={() => navigate('/pages')}>
                <FileText size={16} className="text-text" />
                <span>Manage Pages</span>
              </div>
            )}
            {!!business?.appsConfig?.documents && (
              <div
                className={`sidebar-menu-item item-left-border${isActiveTab('/documents') ? '-primary' : ''}`}
                onClick={() => navigate('/documents')}>
                <FolderClosed size={16} className="text-text" />
                <span>Manage Documents</span>
              </div>
            )}
            {!!business?.appsConfig?.leaves && (
              <div className={`sidebar-menu-item item-left-border${isActiveTab('/leaves') ? '-primary' : ''}`} onClick={() => navigate('/leaves')}>
                <CalendarClock size={16} className="text-text" />
                <span>Manage Leaves</span>
              </div>
            )}
            {(hasSpecificAccess(USER_ROLES.SALES) || hasSpecificAccess(USER_ROLES.SALES_LIMITED)) && !!business?.appsConfig?.sales && (
              <div
                className={`sidebar-menu-item item-left-border${isActiveTab('/sales') ? '-primary' : ''}`}
                onClick={() => navigate('/sales/quotations')}>
                <BadgePercent size={16} className="text-text" />
                <span>Manage Sales</span>
              </div>
            )}
            {(hasSpecificAccess(USER_ROLES.PURCHASES) || hasSpecificAccess(USER_ROLES.PURCHASES_LIMITED)) && !!business?.appsConfig?.purchases && (
              <div
                className={`sidebar-menu-item item-left-border${isActiveTab('/purchases') ? '-primary' : ''}`}
                onClick={() => navigate('/purchases/orders')}>
                <ShoppingCart size={16} className="text-text" />
                <span>Manage Purchases</span>
              </div>
            )}
          </div>
        </>
      )}
    </ProfileMini>
  );
};

export default HomeSidebar;
