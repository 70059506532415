import React from 'react';
import { DateService } from '@worklage/shared';
import { Link } from 'react-router-dom';
import { isLogin } from '../../../services/AuthService';

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-menu text-muted text-center">
        <Link to={isLogin() ? '/about' : '/'} className="text-muted no-underline">
          <span className="cursor-pointer">About</span>
        </Link>
        <span> &middot; </span>
        <Link to="/careers" className="text-muted no-underline">
          <span className="cursor-pointer">Careers</span>
        </Link>
        <span> &middot; </span>
        <Link to="/terms-of-use" className="text-muted no-underline">
          <span className="cursor-pointer">Terms</span>
        </Link>
        <span> &middot; </span>
        <Link to="/privacy-policies" className="text-muted no-underline">
          <span className="cursor-pointer">Privacy</span>
        </Link>
      </div>
      <div className="footer-copyright text-muted text-center">
        All rights reserved &copy; {DateService.getFullYear()} |{' '}
        <Link className="text-primary" to={isLogin() ? '/about' : '/'}>
          Worklage Technologies
        </Link>
      </div>
    </div>
  );
};

export default Footer;
