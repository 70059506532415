import React, { useState } from 'react';
import { formatQueryData, useBusinessData, useUserStore } from '@worklage/shared';
import { NDialog, NLink, NLoading } from 'nayan';
import { getUserImage } from '../../services/WebUtils';

const BusinessLazy = React.lazy(() => import('./Business'));

const BusinessSwitch = () => {
  const [showModal, setShowModal] = useState(false);
  const businessUuid = useUserStore(state => state.UBUUID);
  const { isLoading, data } = useBusinessData(businessUuid);
  const business = formatQueryData(data);

  if (isLoading) {
    return <></>;
  }

  return (
    <React.Suspense fallback={<NLoading />}>
      <div className="box box-margin p-2">
        <NDialog title="Switch Business" isOpen={showModal} onClose={() => setShowModal(false)}>
          <div className="w-full h-full bg-background">
            <BusinessLazy showTitle={false} isModal={true} />
          </div>
        </NDialog>
        <div className="flex flex-row items-center justify-between">
          <div className="flex flex-row items-center">
            <img className="image-container w-[40px] h-[40px] mr-2" src={getUserImage(business.logo)} alt="" loading="lazy" />
            <div>
              <div className="text text-sm limit-one-line mb-0.5">{business.name}</div>
              <div className="text-muted text-xs limit-one-line">Switch Business</div>
            </div>
          </div>
          <NLink className="py-2 pl-2 font-semibold" onClick={() => setShowModal(true)}>
            Switch
          </NLink>
        </div>
      </div>
    </React.Suspense>
  );
};

export default BusinessSwitch;
