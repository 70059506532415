import React from 'react';
import { formatInfiniteQueryData, useGroupSuggestionsData } from '@worklage/shared';
import { NLoading } from 'nayan';
import { useNavigate } from 'react-router-dom';
import NoData from '../helpers/no-data/NoData';
import GroupsItem from './GroupsItem';

const GroupsSuggestions = () => {
  const navigate = useNavigate();
  const { isLoading, data } = useGroupSuggestionsData();
  const groups = formatInfiniteQueryData(data) || [];

  if (isLoading) {
    return <NLoading />;
  }

  return (
    <div className="box p-0">
      {!isLoading && !!groups.length && (
        <>
          <div className="profile-sugg-header text">
            Suggestions
            <span onClick={() => navigate('/groups')} className="cursor-pointer text-primary">
              More
            </span>
          </div>
          {groups.slice(0, 5).map((group: any, i: number) => (
            <GroupsItem isMini={true} group={group} key={group.uuid + i} />
          ))}
        </>
      )}
      {!isLoading && !groups.length && <NoData text="No group suggestions available!" />}
    </div>
  );
};

export default GroupsSuggestions;
