import React from 'react';
import { CONFIG, DateService } from '@worklage/shared';
import { Facebook, Linkedin, Twitter, Youtube } from 'lucide-react';
import { NDivider } from 'nayan';
import { Link } from 'react-router-dom';
import { isLogin } from '../../services/AuthService';

const Footer = () => {
  return (
    <section className="bg-card flex flex-col justify-center text">
      <NDivider />
      <div className="container mx-auto">
        <div className="grid md:grid-cols-8 col-span-3 gap-12 pb-3 pt-5">
          <div className="md:col-span-3 col-span-1">
            <div className="text-base text-text mb-2">ABOUT WORKLAGE</div>
            <div className="text-sm leading-8">
              Worklage is not just a collaboration tool, it can also be Intranet for your business where everyone can share their ideas and
              achievements and celebrate them together. Worklage also automates your daily tasks in one place like, Managing Employees, Leaves,
              Documents, Forms, Assets, Policies, Projects, Sales, Purchases, Invoices and lot more.
            </div>
          </div>
          <div className="col-span-1" />
          <div className="md:col-span-2 col-span-1">
            <div className="text-base text-text mb-2">QUICK LINKS</div>
            <ul className="text-text text-sm m-0 list-none">
              <li className="leading-9 cursor-pointer">
                <Link to={isLogin() ? '/about' : '/'} className="text-text hover:text-primary no-underline py-2.5">
                  About Us
                </Link>
              </li>
              <li className="leading-9 cursor-pointer">
                <Link to="/careers" className="text-text hover:text-primary no-underline py-2.5">
                  Our Careers
                </Link>
              </li>
              <li className="leading-9 cursor-pointer">
                <Link to="/terms-of-use" className="text-text hover:text-primary no-underline py-2.5">
                  Terms of Use
                </Link>
              </li>
              <li className="leading-9 cursor-pointer">
                <Link to="/privacy-policies" className="text-text hover:text-primary no-underline py-2.5">
                  Privacy Policies
                </Link>
              </li>
            </ul>
          </div>
          <div className="md:col-span-2 col-span-1">
            <div className="text-base text-text mb-2">CONTACT US</div>
            <div className="text-sm text leading-7">
              <strong>Address:</strong> &nbsp;#112, 1st cross, 9th main, 1st stage, BTM Layout, Bangalore - 29
            </div>
            <div className="text-sm text leading-8 py-3">
              <strong>Emails:</strong> &nbsp;
              <a className="text-text" href="mailto:hello@worklage.com" target="_blank">
                hello@worklage.com
              </a>
            </div>
            <div className="text-sm text leading-7 mb-2">
              <strong>Social Media:</strong>
            </div>
            <div className="text flex items-center my-5 mt-3">
              <a
                className="text-text hover:text-primary no-underline"
                href="https://www.facebook.com/worklage"
                target="_blank"
                aria-label="Worklage Technologies Facebook">
                <span className="pr-4 cursor-pointer">
                  <Facebook size="1.6em" />
                </span>
              </a>
              {/*<a className="text-text hover:text-primary no-underline" href="https://www.instagram.com/worklagetech" target="_blank" aria-label="Worklage Instagram">*/}
              {/*  <span className="pr-4 cursor-pointer">*/}
              {/*    <IoLogoInstagram size="1.6em" />*/}
              {/*  </span>*/}
              {/*</a>*/}
              <a
                className="text-text hover:text-primary no-underline"
                href="https://twitter.com/worklage"
                target="_blank"
                aria-label="Worklage Technologies Twitter">
                <span className="pr-4 cursor-pointer">
                  <Twitter size="1.6em" />
                </span>
              </a>
              <a
                className="text-text hover:text-primary no-underline"
                href="https://www.linkedin.com/company/worklage"
                target="_blank"
                aria-label="Worklage Technologies Linkedin">
                <span className="pr-4 cursor-pointer">
                  <Linkedin size="1.6em" />
                </span>
              </a>
              <a
                className="text-text hover:text-primary no-underline"
                href="https://www.youtube.com/@worklage"
                target="_blank"
                aria-label="Worklage Technologies Youtube">
                <span className="pr-4 cursor-pointer">
                  <Youtube size="1.6em" />
                </span>
              </a>
            </div>
          </div>
        </div>
        <NDivider />
        <div className="md:flex justify-between items-center py-3">
          <div className="text-sm text text-center md:mb-0 mb-2">All Rights Reserved © {DateService.getFullYear()}</div>
          <div className="text-sm text text-center">
            Powered by{' '}
            <Link to="/" className="text-primary font-semibold no-underline" aria-label="Worklage Technologies">
              Worklage Technologies
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
