import React from 'react';
import { Settings } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { Storage } from '../../services/StorageService';
import { getUserImage } from '../../services/WebUtils';

interface Props {
  children: any;
}

const ProfileMini = (props: Props) => {
  const navigate = useNavigate();
  const myImage = Storage.get('UIMAGE');
  const myName = Storage.get('UNAME');

  return (
    <>
      <div className="profile-sidebar box box-margin p-2">
        <div className="flex flex-row items-center">
          <img
            className="image-container w-[25px] h-[25px] mr-[10px] cursor-pointer"
            src={getUserImage(myImage)}
            alt=""
            loading="lazy"
            onClick={() => navigate('/profile')}
          />
          <div className="w-full flex justify-between items-center">
            <div className="text text-[14px] limit-one-line cursor-pointer" onClick={() => navigate('/profile')}>
              {myName}
            </div>
            <div className="settings-icon cursor-pointer" onClick={() => navigate('/settings')}>
              <Settings className="w-5 h-5 text-muted" />
            </div>
          </div>
        </div>
      </div>
      {props.children}
    </>
  );
};

export default ProfileMini;
