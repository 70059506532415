import React, { useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import {
  addNewChatsUser,
  handleNewNotification,
  onAddTaskMutationSuccess,
  onDeleteTaskMutationSuccess,
  onMutateUpdateTasksData,
  onUpdateTasksMutationSuccess,
  QUERY_KEYS,
  SOCKET_ACTIONS,
  socketConnect,
  updateChatsUserStatus,
  useAppStore,
  useUserStore
} from '@worklage/shared';
import { NTheme, useLocalStorage } from 'nayan';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { is_touch_enabled } from '../../../services/WebUtils';

interface Props {
  children: any;
}

const AppWrapper = (props: Props) => {
  const queryClient = useQueryClient();
  const uuid = useUserStore((state: any) => state.UUID);
  const [theme] = useLocalStorage('THEME', '');
  const appSocket = useAppStore((state: any) => state.appSocket);
  const dndBackend = is_touch_enabled() ? TouchBackend : HTML5Backend;

  useEffect(() => {
    appSocket && appSocket.close();
    if (uuid) {
      const socketCon = socketConnect();
      useAppStore.setState({ appSocket: socketCon });
      console.log('SOCKET CONNECTED', uuid);
      socketCon.on(SOCKET_ACTIONS.UPDATE_USER_STATUS_RECEIVE, userStatusHandler);
      socketCon.on(SOCKET_ACTIONS.UPDATE_CHAT_USERS_RECEIVE, chatUsersHandler);
      socketCon.on(SOCKET_ACTIONS.NEW_NOTIFICATION_RECEIVE, newNotificationHandler);
      socketCon.on(SOCKET_ACTIONS.NEW_TASKS_RECEIVE, newTasksHandler);
      socketCon.on(SOCKET_ACTIONS.UPDATE_TASKS_RECEIVE, updateTasksHandler);
      socketCon.on(SOCKET_ACTIONS.DELETE_TASKS_RECEIVE, deleteTasksHandler);
    }
  }, [uuid]);

  const chatUsersHandler = (user: any) => {
    const businessUuid = useUserStore.getState().UBUUID;
    if (user.businessUuid === businessUuid) {
      useAppStore.setState({ messages: 1 });
      return addNewChatsUser(queryClient, [QUERY_KEYS.CHATS], user);
    }
  };

  const userStatusHandler = (data: any) => {
    return updateChatsUserStatus(queryClient, [QUERY_KEYS.CHATS], data);
  };

  const newNotificationHandler = (data: any) => {
    const businessUuid = useUserStore.getState().UBUUID;
    if (data.businessUuid === businessUuid) {
      useAppStore.setState({ notifications: 1 });
      handleNewNotification(queryClient, data);
    }
  };

  const newTasksHandler = (data: any) => {
    onAddTaskMutationSuccess(queryClient, { data });
  };

  const updateTasksHandler = (data: any) => {
    onMutateUpdateTasksData(queryClient, data);
    onUpdateTasksMutationSuccess(queryClient, { data });
  };

  const deleteTasksHandler = (data: any) => {
    onDeleteTaskMutationSuccess(queryClient, { data });
  };

  return (
    <NTheme theme={theme}>
      <DndProvider backend={dndBackend}>
        <>{props.children}</>
        <ReactQueryDevtools initialIsOpen={false} buttonPosition="bottom-left" />
      </DndProvider>
    </NTheme>
  );
};

export default AppWrapper;
