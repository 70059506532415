import React, { useState } from 'react';
import { AUTH_TABS, signupUser } from '@worklage/shared';
import { Field, Form, Formik } from 'formik';
import * as _ from 'lodash';
import { NButton, NCheck, useToast } from 'nayan';
import { Link, useNavigate } from 'react-router-dom';
import Required from '../helpers/elements/Required';
import Seo from '../helpers/Seo';
import AcceptTerms from '../helpers/static/AcceptTerms';

interface Props {
  tabChange: any;
}

const Signup: React.FC<Props> = (props: Props) => {
  const toast = useToast();
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const initialValues = { name: '', email: '', password: '', business: '' };

  return (
    <>
      <Seo title="Signup | Worklage" />
      <Formik
        initialValues={initialValues}
        onSubmit={(values, actions) => {
          if (!values.name || !values.email || !values.password || !values.business) {
            toast('You have to enter required details to continue.');
            return;
          }
          if (!isActive) {
            toast('You have to accept Our Terms to continue.');
            return;
          }
          setIsLoading(true);
          const userData = _.assign({ name: values.name, email: values.email, password: values.password, businessName: values.business });
          signupUser(userData)
            .then((res: any) => {
              toast(res.data.message);
              navigate('/login', { replace: true });
            })
            .catch((error: any) => {
              toast(error.message);
            })
            .finally(() => setIsLoading(false));
        }}>
        <Form>
          <div className="mb-2">
            <label className="text" htmlFor="name">
              Full name
              <Required />
            </label>
            <Field className="form-control bg-card" type="text" name="name" />
          </div>

          <div className="mb-2">
            <label className="text" htmlFor="name">
              Business name
              <Required />
            </label>
            <Field className="form-control bg-card" type="text" name="business" />
          </div>

          <div className="mb-2">
            <label className="text" htmlFor="email">
              Email
              <Required />
            </label>
            <Field className="form-control bg-card" type="email" name="email" />
          </div>

          <div className="mb-2">
            <label className="text" htmlFor="password">
              Password
              <Required />
            </label>
            <Field className="form-control bg-card" type="password" name="password" />
          </div>

          <div className="form-check mb-2">
            <NCheck id="signup-check" checked={isActive} onChange={(checked: any) => setIsActive(checked)}>
              <AcceptTerms />
              <Required />
            </NCheck>
          </div>

          <div className="d-grid gap-3 mb-2">
            <NButton type="submit" className="w-full" isLoading={isLoading}>
              SUBMIT
            </NButton>
          </div>
        </Form>
      </Formik>

      <div className="text-center text">
        Already have an account ?{' '}
        <Link className="text-primary cursor-pointer" to="/login">
          Login
        </Link>
      </div>
    </>
  );
};

export default Signup;
