import React, { useEffect, useRef, useState } from 'react';
import { clearLineBreaks, RestService } from '@worklage/shared';
import * as _ from 'lodash';
import { NLink, NLoading } from 'nayan';
import { useNavigate } from 'react-router-dom';
import { getUserImage } from '../../../services/WebUtils';

interface Props {}

const SearchBox = (props: Props) => {
  const [data, setData] = useState([]);
  const [text, setText] = useState('');
  const [loading, setLoading] = useState(false);
  const [searching, setSearching] = useState(false);

  const ref = useRef(null);
  const searchRef = useRef(null);
  const navigate = useNavigate();

  const handleClickOutside = (e: any) => {
    // @ts-ignore
    if (ref.current && !ref.current.contains(e.target)) {
      setSearching(false);
      // @ts-ignore
      searchRef.current.value = '';
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  });

  const searchUsers = (text: string): any => {
    setText(text);
    if (!text) return;
    setLoading(true);
    return RestService.get('/users?text=' + text + '&page=1').then(res => {
      setData(res.data);
      setLoading(false);
    });
  };

  const debounceOnChange = React.useCallback(_.debounce(searchUsers, 1000), []);

  return (
    <div className="-my-2 ml-2" ref={ref}>
      <input
        type="text"
        className="form-control search-box-input"
        placeholder="Search users"
        ref={searchRef}
        onFocus={() => setSearching(true)}
        onChange={e => debounceOnChange(e.target.value)}
      />
      {searching && (
        <div className="search-box-results bg-card border border-border">
          {loading && <NLoading />}
          {!loading &&
            data.length > 0 &&
            data.slice(0, 3).map((item: any) => {
              return (
                <div className="cursor-pointer p-2 search-box-item bg-card" key={item.uuid} onClick={() => navigate('/profile/' + item.uuid)}>
                  <img src={getUserImage(item.image)} className="image-container search-user-image border border-border" alt="logo" />
                  <div>
                    <div className="text-text">{item.name}</div>
                    <div className="text-text text-xs limit-one-line">{clearLineBreaks(item.about) || item.id}</div>
                  </div>
                </div>
              );
            })}
          {!loading && data.length == 0 && <div className="p-3 text-center text">No users with your search.</div>}
          {data.length > 3 && (
            <div className="p-2">
              <NLink className="p-2" onClick={() => navigate('/community?search=' + text)}>
                See more
              </NLink>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SearchBox;
