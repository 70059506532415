import React from 'react';
import { PROJECT_STATUS } from '@worklage/shared';
import { NLoading } from 'nayan';
import WrapperTwoColumn, { SIDEBAR_BLOCK_TYPE } from '../../components/helpers/wrapper/WrapperTwoColumn';

const ProjectsListLazy = React.lazy(() => import('./ProjectsListLazy'));

interface Props {
  type: PROJECT_STATUS;
}

const ProjectsList = (props: Props) => {
  return (
    <WrapperTwoColumn type={SIDEBAR_BLOCK_TYPE.HOME}>
      <React.Suspense fallback={<NLoading />}>
        <ProjectsListLazy {...props} />
      </React.Suspense>
    </WrapperTwoColumn>
  );
};

export default ProjectsList;
