import React from 'react';
import { PROJECT_STATUS } from '@worklage/shared';
import { useLocation } from 'react-router-dom';
import ProjectsList from './ProjectsList';

const Projects = () => {
  const search = useLocation().search;
  const type: any = new URLSearchParams(search).get('type') || PROJECT_STATUS.ACTIVE;

  return <>{[PROJECT_STATUS.ACTIVE, PROJECT_STATUS.ARCHIVE].includes(type.toUpperCase()) && <ProjectsList type={type} />}</>;
};

export default Projects;
