import React, { useState } from 'react';
import { formatInfiniteQueryData, isExternal, useGroupsData } from '@worklage/shared';
import { NDialog, NInfiniteScroll, NLoading } from 'nayan';
import SubHeader from '../helpers/header/SubHeader';
import NoData from '../helpers/no-data/NoData';
import GroupNew from './GroupNew';
import GroupsItem from './GroupsItem';
import GroupsMenu from './GroupsMenu';

const MyGroupsLazy = () => {
  const [showModal, setShowModal] = useState(false);
  const { isLoading, data, fetchNextPage, hasNextPage, isFetching } = useGroupsData();
  const groups = formatInfiniteQueryData(data);

  return (
    <>
      <NDialog isOpen={showModal} title="Add Group" onClose={() => setShowModal(false)}>
        <GroupNew closeModal={() => setShowModal(!showModal)} />
      </NDialog>
      {isLoading && <NLoading />}
      <NInfiniteScroll
        next={() => !isFetching && fetchNextPage()}
        hasMore={!!hasNextPage}
        loader={<NLoading />}
        dataLength={groups.length}
        scrollThreshold={0.99}>
        {!isLoading && (
          <SubHeader hasSeo title="My Groups" showButton={!isExternal()} clickButton={() => setShowModal(!showModal)}>
            <GroupsMenu />
          </SubHeader>
        )}
        <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2.5 mb-2.5">
          {groups.map((group: any, i: number) => (
            <GroupsItem group={group} key={group.uuid + i} />
          ))}
        </div>
      </NInfiniteScroll>
      {!isLoading && !groups.length && <NoData text="You are not part of any group, add one now!" />}
    </>
  );
};

export default MyGroupsLazy;
