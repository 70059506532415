import React, { useState } from 'react';
import { QueryCache, useQueryClient } from '@tanstack/react-query';
import { loginUser, RestService } from '@worklage/shared';
import { Field, Form, Formik } from 'formik';
import { NButton, useToast } from 'nayan';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Storage } from '../../services/StorageService';
import Required from '../helpers/elements/Required';
import Seo from '../helpers/Seo';

const Login = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const restCache = new QueryCache();
  const queryClient = useQueryClient();
  const search = useLocation().search;
  const redirect: any = new URLSearchParams(search).get('redirect') || '/timeline';
  const [isLoading, setIsLoading] = useState(false);
  const initialValues = { email: '', password: '' };

  return (
    <>
      <Seo title="Login | Worklage" />
      <Formik
        initialValues={initialValues}
        onSubmit={(values, actions) => {
          if (!values.email || !values.password) {
            toast('You have to enter required details to continue.');
            return;
          }
          setIsLoading(true);
          const data = JSON.stringify(values, null, 2);
          loginUser(data)
            .then(async (res: any) => {
              Storage.set('TOKEN', res.token);
              Storage.set('UROLE', res.roles);
              Storage.set('UUID', res.uuid);
              Storage.set('UBUUID', res.businessUuid);
              Storage.set('UNAME', res.name);
              Storage.set('UIMAGE', res.image);
              await restCache.clear();
              await queryClient.clear();
              await queryClient.removeQueries();
              await queryClient.invalidateQueries();
              RestService.setToken(res.token);
              navigate(redirect, { replace: true });
            })
            .catch((error: any) => {
              toast(error.message);
            })
            .finally(() => setIsLoading(false));
        }}>
        <Form>
          <div className="mb-2">
            <label className="text" htmlFor="email">
              Email
              <Required />
            </label>
            <Field className="form-control bg-card" type="email" name="email" aria-label="Email" />
          </div>

          <div className="mb-2">
            <label className="text" htmlFor="password">
              Password
              <Required />
            </label>
            <Field className="form-control bg-card" type="password" name="password" aria-label="Password" />
          </div>

          <div className="mb-2 text-end">
            <Link to="/forgot-password" className="text-primary cursor-pointer">
              Forgot password ?
            </Link>
          </div>

          <div className="d-grid gap-3 mb-2">
            <NButton type="submit" className="w-full" isLoading={isLoading}>
              LOGIN
            </NButton>
          </div>
        </Form>
      </Formik>

      <div className="text-center text">
        Don't have an account ?{' '}
        <Link to="/signup" className="text-primary cursor-pointer">
          Signup
        </Link>
      </div>
    </>
  );
};

export default Login;
