import React from 'react';

const Credits = () => {
  return (
    <section className="bg-background flex flex-col justify-center antialiased text">
      <div className="container mx-auto p-8">
        <div className="grid sm:grid-cols-2 md:grid-cols-4 grid-cols-1 gap-8">
          <div className="col-span-1 flex flex-col justify-center items-center">
            <div className="text-4xl text mb-3">100+</div>
            <div className="text-sm text">PAID CUSTOMERS</div>
          </div>
          <div className="col-span-1 flex flex-col justify-center items-center">
            <div className="text-4xl text mb-3">500+</div>
            <div className="text-sm text">FREE CUSTOMERS</div>
          </div>
          <div className="col-span-1 flex flex-col justify-center items-center">
            <div className="text-4xl text mb-3">10+</div>
            <div className="text-sm text">COUNTRIES REACH</div>
          </div>
          <div className="col-span-1 flex flex-col justify-center items-center">
            <div className="text-4xl text mb-3">1M+</div>
            <div className="text-sm text">DATA EVENTS PER MONTH</div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Credits;
