import React from 'react';
import { useUserStore } from '@worklage/shared';
import { Navigate, Outlet } from 'react-router-dom';

const ProtectedRoutes = () => {
  const isAuthenticated = useUserStore(state => state.UUID);
  const currentLocation = location.pathname;

  return isAuthenticated ? <Outlet /> : <Navigate to={`/login?redirect=${currentLocation}`} />;
};

export default ProtectedRoutes;
