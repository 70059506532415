import React from 'react';
import { formatQueryData, GROUP_TABS, useGroupData } from '@worklage/shared';
import { NLoading } from 'nayan';
import { useParams } from 'react-router';
import Seo from '../helpers/Seo';
import WrapperThreeColumn from '../helpers/wrapper/WrapperThreeColumn';
import WrapperTwoColumn, { SIDEBAR_BLOCK_TYPE } from '../helpers/wrapper/WrapperTwoColumn';
import GroupInfo from './GroupInfo';

const Posts = React.lazy(() => import('./posts/Posts'));
const Users = React.lazy(() => import('./users/Users'));
const Settings = React.lazy(() => import('./settings/Settings'));

const GroupDetails = () => {
  const params: any = useParams();
  const selectedTab = params.tab || GROUP_TABS.POSTS;
  const { isLoading, data } = useGroupData(params.uuid);
  const group = formatQueryData(data);

  return (
    <>
      {selectedTab === GROUP_TABS.POSTS && (
        <WrapperThreeColumn type={SIDEBAR_BLOCK_TYPE.GROUP}>
          <React.Suspense fallback={<NLoading />}>
            {isLoading && <NLoading />}
            {!isLoading && (
              <div className="block md:hidden">
                <Seo title={`${group.name} | Worklage`} />
                <GroupInfo />
              </div>
            )}
            {!isLoading && <Posts group={group} />}
          </React.Suspense>
        </WrapperThreeColumn>
      )}
      {(selectedTab === GROUP_TABS.USERS || selectedTab === GROUP_TABS.SETTINGS) && (
        <WrapperTwoColumn type={SIDEBAR_BLOCK_TYPE.GROUP}>
          <React.Suspense fallback={<NLoading />}>
            {isLoading && <NLoading />}
            {!isLoading && (
              <div className="block md:hidden">
                <Seo title={`${group.name} | Worklage`} />
                <GroupInfo />
              </div>
            )}
            {!isLoading && (
              <>
                {selectedTab === GROUP_TABS.USERS && <Users group={group} />}
                {selectedTab === GROUP_TABS.SETTINGS && <Settings group={group} />}
              </>
            )}
          </React.Suspense>
        </WrapperTwoColumn>
      )}
    </>
  );
};

export default GroupDetails;
