import React from 'react';
import { formatQueryData, PROJECT_TABS, useProjectData } from '@worklage/shared';
import { CalendarDays, FileText, PieChart, Settings, Users2 } from 'lucide-react';
import { NLoading } from 'nayan';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import GridTags from '../../components/helpers/grid/GridTags';
import { getProjectImage, getUserImage } from '../../services/WebUtils';

const ProjectInfo = () => {
  const params: any = useParams();
  const navigate = useNavigate();
  const selectedTab = params.tab || PROJECT_TABS.TASKS;
  const { isLoading, data } = useProjectData(params.uuid);
  const project = formatQueryData(data);

  const onChangeTab = (selectedTab: string) => {
    navigate('/projects/' + params.uuid + '/' + selectedTab);
  };

  if (isLoading) {
    return <NLoading />;
  }

  return (
    <>
      <div className="box-margin">
        {project && (
          <div className="cursor-pointer h-full break-inside box-margin">
            <div className="box overflow-visible p-0 sugg-item-block">
              <div className="sugg-user-block-grid p-0">
                <div className="cursor-pointer">
                  {project.image && (
                    <div className="mb-0">
                      <img
                        src={getProjectImage(project.image)}
                        className="image-container object-cover object-center w-full h-[150px] rounded rounded-b-none border-0"
                        alt="user"
                        loading="lazy"
                      />
                    </div>
                  )}
                  <div className="px-2 mt-2">
                    <div className="flex justify-between items-center">
                      <div className="m-0 limit-one-line text pb-1">{project.name}</div>
                    </div>
                    <div className="m-0 text-muted text-xs pb-2">{project.about}</div>
                  </div>
                  <div className="tags flex flex-wrap px-2">
                    <GridTags data={project} />
                  </div>
                  <div className="pt-1 px-2 pb-2 flex items-center justify-between">
                    <div className="flex items-center">
                      <img src={getUserImage(project?.user?.image)} className="image-container h-[15px] w-[15px] rounded-full mr-1" alt="logo" />
                      <span className="text text-xs">{project?.user?.name}</span>
                    </div>
                    <div className="flex items-center">
                      <span className="flex items-center text-muted text-xs mr-2">
                        <Users2 size={15} className="text-text" />
                        <span className="ml-1 text-xs">{project.users}</span>
                      </span>
                      <span className="flex items-center text-muted text-sm">
                        <FileText size={13} className="text-text" />
                        <span className="ml-1 text-xs">{project.tasks}</span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {project && (
        <div className="box box-margin item-radius p-0 community-menu">
          <div
            className={`sidebar-menu-item item-left-border${selectedTab === PROJECT_TABS.TASKS ? '-primary' : ''}`}
            onClick={() => onChangeTab(PROJECT_TABS.TASKS)}>
            <FileText size={16} className="text-text" />
            <span>Manage Tasks</span>
          </div>
          <div
            className={`sidebar-menu-item item-left-border${selectedTab === PROJECT_TABS.TARGETS ? '-primary' : ''}`}
            onClick={() => onChangeTab(PROJECT_TABS.TARGETS)}>
            <CalendarDays size={16} className="text-text" />
            <span>Manage Targets</span>
          </div>
          <div
            className={`sidebar-menu-item item-left-border${selectedTab === PROJECT_TABS.USERS ? '-primary' : ''}`}
            onClick={() => onChangeTab(PROJECT_TABS.USERS)}>
            <Users2 size={16} className="text-text" />
            <span>Manage Users</span>
          </div>
          <div
            className={`sidebar-menu-item item-left-border${selectedTab === PROJECT_TABS.ANALYTICS ? '-primary' : ''}`}
            onClick={() => onChangeTab(PROJECT_TABS.ANALYTICS)}>
            <PieChart size={16} className="text-text" />
            <span>Manage Analytics</span>
          </div>
          <div
            className={`sidebar-menu-item item-left-border${selectedTab === PROJECT_TABS.SETTINGS ? '-primary' : ''}`}
            onClick={() => onChangeTab(PROJECT_TABS.SETTINGS)}>
            <Settings size={16} className="text-text" />
            <span>Settings</span>
          </div>
        </div>
      )}
    </>
  );
};

export default ProjectInfo;
