import React, { useState } from 'react';
import { useDeleteGroupMutation } from '@worklage/shared';
import { Eye, MoreVertical, Trash2 } from 'lucide-react';
import { NConfirmAlert, NDialog, NMenu, NMenuItem } from 'nayan';
import { useToast } from 'nayan';
import GroupNew from './GroupNew';

interface Props {
  group: any;
}

const GroupsItemMenu = (props: Props) => {
  const { group } = props;
  const toast = useToast();
  const [showAlert, setShowAlert] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const deleteGroupMutation = useDeleteGroupMutation();

  return (
    <>
      <NConfirmAlert
        isOpen={showAlert}
        message="This action will delete permanently, Are you sure to delete this group?"
        onResult={result => result && deleteGroupMutation.mutateAsync(group.uuid).then(() => toast(group.name + ' group deleted successfully.'))}
        onClose={() => setShowAlert(false)}
      />
      <NDialog title="Edit Group" isOpen={showModal} onClose={() => setShowModal(false)}>
        <GroupNew group={group} closeModal={() => setShowModal(false)} />
      </NDialog>
      <NMenu trigger={<MoreVertical className="text-muted w-5 h-5 mt-2" />}>
        <NMenuItem title="Edit" icon={Eye} onClick={() => setShowModal(true)} />
        <NMenuItem title="Delete" icon={Trash2} onClick={() => setShowAlert(true)} />
      </NMenu>
    </>
  );
};

export default React.memo(GroupsItemMenu);
