import React, { useEffect, useState } from 'react';
import { RESOURCES, verifyAccount } from '@worklage/shared';
import { NLoading } from 'nayan';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import Seo from '../helpers/Seo';
import Wrapper from '../helpers/wrapper/Wrapper';

const Verify = () => {
  const navigate = useNavigate();
  const { userUuid, resetUuid }: any = useParams();
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    verifyAccount({ userUuid, resetUuid })
      .then((res: any) => setMessage(res.data.message))
      .catch((err: any) => setMessage(err.message))
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <Wrapper>
      <Seo title="Verify Account | Worklage" />
      <div className="flex justify-center">
        <div className="w-full md:max-w-[450px]">
          {isLoading && <NLoading />}
          {!isLoading && (
            <div className="text-center">
              <img src={RESOURCES.WAITING} alt="" className="w-[200px] mt-5" />
              <div className="text mb-5 mt-5">{message}</div>
              <div className="btn btn-sm btn-primary" onClick={() => navigate('/login', { replace: true })}>
                Click here to Login
              </div>
            </div>
          )}
        </div>
      </div>
    </Wrapper>
  );
};

export default Verify;
