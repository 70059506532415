import React, { useState } from 'react';
import { formatQueryData, GROUP_TABS, useFollowGroupMutation, useGroupData, useUserStore } from '@worklage/shared';
import { LayoutGrid, Settings, Users2 } from 'lucide-react';
import { ButtonSize, NButton, NDialog, NLoading } from 'nayan';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { getUserImage } from '../../services/WebUtils';
import ChatsNew from '../chats/ChatsNew';

const GroupInfo = () => {
  const params: any = useParams();
  const navigate = useNavigate();
  const selectedTab = params.tab || GROUP_TABS.POSTS;
  const myUuid = useUserStore.getState().UUID;
  const followGroupMutation = useFollowGroupMutation();
  const [showMsgModal, setShowMsgModal] = useState(false);
  const { isLoading, data } = useGroupData(params.uuid);
  const group = formatQueryData(data);

  const onChangeTab = (selectedTab: GROUP_TABS) => {
    navigate('/groups/' + params.uuid + '/' + selectedTab);
  };

  const followGroup = (e: any) => {
    e.stopPropagation();
    selectedTab === GROUP_TABS.SETTINGS && onChangeTab(GROUP_TABS.POSTS);
    followGroupMutation.mutate({ type: e.target.innerText, group });
  };

  if (isLoading) {
    return <NLoading />;
  }

  if (!group) {
    return <></>;
  }

  return (
    <>
      <NDialog title="New Message" isOpen={showMsgModal} onClose={() => setShowMsgModal(false)}>
        <ChatsNew user={group} isGroup={true} closeModal={() => setShowMsgModal(!showMsgModal)} />
      </NDialog>
      <div className="box-margin">
        <div className="cursor-pointer h-full">
          <div className="box p-3 sugg-item-block p-2">
            <div className="sugg-user-block-grid">
              <div className="cursor-pointer text-center">
                <img className="image-container h-[90px] w-[90px] rounded-full mb-1" src={getUserImage(group.image)} alt="" loading="lazy" />
                <div className="mt-1 mb-1 text text-[14px] font-semibold text-center">{group.name}</div>
                <div className="m-0 text-muted pb-1">{group.about}</div>
                <div className="text text-center">
                  {group.followers} &nbsp;<span className="text">Followers</span>
                </div>
                <div className="text-center pt-2">
                  {!!group.isFollowing && (
                    <NButton size={ButtonSize.SM} className="mx-1" onClick={() => setShowMsgModal(true)}>
                      Message
                    </NButton>
                  )}
                  {group.userUuid !== myUuid && (
                    <NButton size={ButtonSize.SM} className="mx-1" onClick={e => followGroup(e)}>
                      {!!group.isFollowing ? 'Following' : 'Follow'}
                    </NButton>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="box box-margin item-radius p-0 community-menu">
        <div
          className={`sidebar-menu-item item-left-border${selectedTab === GROUP_TABS.POSTS ? '-primary' : ''}`}
          onClick={() => onChangeTab(GROUP_TABS.POSTS)}>
          <LayoutGrid size={16} className="text-text" />
          <span>Manage Posts</span>
        </div>
        <div
          className={`sidebar-menu-item item-left-border${selectedTab === GROUP_TABS.USERS ? '-primary' : ''}`}
          onClick={() => onChangeTab(GROUP_TABS.USERS)}>
          <Users2 size={16} className="text-text" />
          <span>Manage Users</span>
        </div>
        {!!group.isFollowing && (
          <div
            className={`sidebar-menu-item item-left-border${selectedTab === GROUP_TABS.SETTINGS ? '-primary' : ''}`}
            onClick={() => onChangeTab(GROUP_TABS.SETTINGS)}>
            <Settings size={16} className="text-text" />
            <span>Settings</span>
          </div>
        )}
      </div>
    </>
  );
};

export default GroupInfo;
