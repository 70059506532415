import React from 'react';
import BusinessSwitch from '../../../applications/business/BusinessSwitch';
import ProjectInfo from '../../../applications/projects/ProjectInfo';
import CommunitySuggestions from '../../community/CommunitySuggestions';
import GroupInfo from '../../groups/GroupInfo';
import GroupsMiniSuggestions from '../../groups/GroupsMiniSuggestions';
import HomeSidebar from '../../home/HomeSidebar';
import Wrapper from './Wrapper';
import WrapperLeft from './WrapperLeft';

interface Props {
  type?: SIDEBAR_BLOCK_TYPE;
  children: any;
}

export enum SIDEBAR_BLOCK_TYPE {
  HOME = 'home',
  GROUP = 'group',
  PROJECT = 'project'
}

export const getSidebarBlockType = (type: SIDEBAR_BLOCK_TYPE) => {
  switch (type) {
    case SIDEBAR_BLOCK_TYPE.HOME:
      return <HomeSidebar />;
    case SIDEBAR_BLOCK_TYPE.GROUP:
      return <GroupInfo />;
    case SIDEBAR_BLOCK_TYPE.PROJECT:
      return <ProjectInfo />;
  }
};

export const getRightSidebarBlockType = (type: SIDEBAR_BLOCK_TYPE) => {
  switch (type) {
    case SIDEBAR_BLOCK_TYPE.HOME:
      return (
        <>
          <BusinessSwitch />
          <CommunitySuggestions />
        </>
      );
    case SIDEBAR_BLOCK_TYPE.GROUP:
      return <GroupsMiniSuggestions />;
    default:
      return <CommunitySuggestions />;
  }
};

const WrapperTwoColumn = (props: Props) => {
  const { type = SIDEBAR_BLOCK_TYPE.HOME } = props;

  return (
    <Wrapper>
      <div className="grid grid-cols-9 gap-2.5">
        <div className="hidden md:block col-span-9 md:col-span-2">
          <WrapperLeft>{getSidebarBlockType(type)}</WrapperLeft>
        </div>
        <div className="col-span-12 md:col-span-7">{props.children}</div>
      </div>
    </Wrapper>
  );
};

export default WrapperTwoColumn;
