import React from 'react';
import { isMyGroup, useFollowGroupMutation, useUserStore } from '@worklage/shared';
import { ButtonSize, NButton, useToast } from 'nayan';
import { useNavigate } from 'react-router-dom';
import { getUserImage } from '../../services/WebUtils';
import GroupsItemMenu from './GroupsItemMenu';

interface Props {
  isMini?: boolean;
  group: any;
}

const GroupsItem = (props: Props) => {
  const { isMini = false, group } = props;
  const navigate = useNavigate();
  const toast = useToast();
  const myUuid = useUserStore.getState().UUID;
  const followGroupMutation = useFollowGroupMutation();

  const followGroup = (e: any) => {
    e.stopPropagation();
    const type = e.target.innerText;
    followGroupMutation.mutateAsync({ type, group }).then(() => {
      toast(`You have successfully ${type === 'Following' ? 'un followed' : 'followed'} ${group.name}.`);
    });
  };

  return (
    <>
      {!isMini && (
        <div className="cursor-pointer h-full relative">
          {isMyGroup(group.userUuid) && (
            <span className="absolute top-[3px] right-[6px]">
              <GroupsItemMenu group={group} />
            </span>
          )}
          <div className="box p-3 sugg-item-block" onClick={() => navigate('/groups/' + group.uuid)}>
            <div className="sugg-user-block-grid">
              <div className="cursor-pointer text-center">
                <img src={getUserImage(group.image)} className="image-container sugg-user-image-grid mx-auto mb-2" alt="user" loading="lazy" />
                <div>
                  <div className="sugg-user-text-grid limit-one-line text mb-1">{group.name}</div>
                  <div className="sugg-user-sub-text-grid text-muted limit-two-line mb-2">{group.about}</div>
                  <div className="text text-center mb-3">
                    {group.followers} &nbsp;<span className="text">Followers</span>
                  </div>
                </div>
              </div>
              {isMyGroup(group.userUuid) && <div style={{ height: 31 }} />}
              {!isMyGroup(group.userUuid) && (
                <div className="text-center">
                  <NButton isOutline size={ButtonSize.SM} onClick={followGroup}>
                    {!!group.isFollowing ? 'Following' : 'Follow'}
                  </NButton>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {isMini && (
        <div className="cursor-pointer h-full">
          <div className="p-2 sugg-item-block no-radius border-0 border-t border-border" onClick={() => navigate('/groups/' + group.uuid)}>
            <div className="sugg-user-block">
              <div className="sugg-user cursor-pointer">
                <img src={getUserImage(group.image)} className="image-container sugg-user-image" alt="user" loading="lazy" />
                <div>
                  <div className="sugg-user-text limit-one-line text mb-1">{group.name}</div>
                  <div className="sugg-user-sub-text text-muted text">
                    {group.followers} &nbsp;<span className="text">Followers</span>
                  </div>
                </div>
              </div>
              {group.userUuid !== myUuid && (
                <div>
                  <NButton size={ButtonSize.SM} isOutline onClick={followGroup}>
                    {!!group.isFollowing ? 'Following' : 'Follow'}
                  </NButton>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default React.memo(GroupsItem);
