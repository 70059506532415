import React from 'react';
import { cn } from 'nayan';

interface Props {
  text: string;
  className?: string;
}

const NoData = (props: Props) => {
  return <div className={cn(`text-center p-3 ${props.className}`)}>{props.text}</div>;
};

export default NoData;
