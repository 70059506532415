import React from 'react';
import { isExternal } from '@worklage/shared';
import { NLoading } from 'nayan';
import MyGroups from '../groups/MyGroups';
import WrapperTwoColumn, { SIDEBAR_BLOCK_TYPE } from '../helpers/wrapper/WrapperTwoColumn';

const CommunityLazy = React.lazy(() => import('./CommunityLazy'));

const Community = () => {
  if (isExternal()) {
    return <MyGroups />;
  }

  return (
    <WrapperTwoColumn type={SIDEBAR_BLOCK_TYPE.HOME}>
      <React.Suspense fallback={<NLoading />}>
        <CommunityLazy />
      </React.Suspense>
    </WrapperTwoColumn>
  );
};

export default Community;
