import React from 'react';
import { NButton } from 'nayan';
import { Link } from 'react-router-dom';

const basicFeatures = [
  'Web, Mobile and Desktop applications.',
  'Unlimited photo, video & file sharing.',
  'Unlimited calling, messaging and Screen sharing.',
  'Complete administrative control on user and content management.',
  'Free HRM tools to manage Employee’s, Documents, Projects, Sales, Purchases, Invoices, Assets, Policies and lot more.'
];

const silverFeatures = [...basicFeatures, '24/7 Email and Whatsapp chat support.'];

const goldFeatures = [...silverFeatures, 'Beta access for new tools and application features.', 'Custom domain integrations.'];

const platinumFeatures = [...goldFeatures, 'Custom security and data storage options.', 'Custom branding and Custom deployments.'];

const pricings = [
  {
    title: 'Basic',
    description: 'Upto 10 members, Lifetime free for small teams and non-profit organisations.',
    button: 'Start Free',
    price: '0',
    features: basicFeatures
  },
  {
    title: 'Startup',
    description: 'Upto 100 members, call us for more custom team sizes and offers.',
    button: 'Start Free',
    price: '100',
    features: silverFeatures
  },
  {
    title: 'Enterprise',
    description: 'Upto 1000 members, call us for more custom team sizes and offers.',
    button: 'Start Free',
    price: '1000',
    features: goldFeatures
  },
  {
    title: 'On-Premise',
    description: 'Use our on premise solution to deploy Worklage based on your preference.',
    button: 'Start Free',
    price: '1000',
    features: platinumFeatures
  }
];

const Pricing = () => {
  return (
    <section className="bg-background flex flex-col justify-center text mb-16" id="pricing">
      <div className="container mx-auto">
        <div className="h-full">
          <div className="mx-auto">
            <div className="text-3xl text text-center mt-12 mb-5">Pricing</div>
            <div className="text text-base mb-8 text-center max-w-[900px] mx-auto">
              Get the power, control, and customization you need to manage your team or organization collaboration. if no plan matches for your
              requirement contact us at{' '}
              <a href="mailto:hello@worklage.com" target="_blank" className="text-primary no-underline">
                hello@worklage.com
              </a>{' '}
              we will be more flexible to meet your requirements.
            </div>
            <div className="grid sm:grid-cols-2 lg:grid-cols-4 grid-cols-1 gap-2.5">
              {pricings.map(pricing => (
                <div key={pricing.title} className="relative col-span-1 bg-card shadow rounded-sm border border-border">
                  <div className={`absolute top-0 left-0 right-0 h-0.5 bg-primary`} aria-hidden="true" />
                  <div className="p-3 border-0 border-b border-border">
                    <header className="flex items-center mb-2">
                      <div className={`w-6 h-6 rounded-full flex-shrink-0 bg-gradient-to-tr from-blue-800 to-blue-400 mr-3`}>
                        <svg className="w-6 h-6 fill-current text-white" viewBox="0 0 24 24">
                          <path d="M12 17a.833.833 0 01-.833-.833 3.333 3.333 0 00-3.334-3.334.833.833 0 110-1.666 3.333 3.333 0 003.334-3.334.833.833 0 111.666 0 3.333 3.333 0 003.334 3.334.833.833 0 110 1.666 3.333 3.333 0 00-3.334 3.334c0 .46-.373.833-.833.833z" />
                        </svg>
                      </div>
                      <h3 className="text-lg text font-semibold">{pricing.title}</h3>
                    </header>
                    <div className="text-sm mb-2 leading-6">{pricing.description}</div>
                    <div className="text font-bold mb-4">
                      <span className="text-2xl">$</span>
                      <span className="text-3xl">{pricing.price}</span>
                      <span className="text font-medium text-base">/month</span>
                    </div>
                    <Link to="/login">
                      <NButton className="uppercase">{pricing.button}</NButton>
                    </Link>
                  </div>
                  <div className="p-3">
                    <div className="text-xs text font-semibold uppercase mb-4">What's included</div>
                    <ul>
                      {pricing.features.map(feature => (
                        <li key={feature} className="flex py-2">
                          <svg className="w-3 h-3 flex-shrink-0 fill-current text-primary mr-3 mt-1" viewBox="0 0 12 12">
                            <path d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z" />
                          </svg>
                          <div className="text-sm">{feature}</div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Pricing;
